import React from "react";

const CrForm = React.forwardRef((props, ref) => {
    const getRows = () => {
        let rows = [];
        for (let i = 1; i < 11; i++) {
            rows.push({value: i, label: i});
        }

        return rows;
    };

    const getColumns = () => {
        let columns = [];
        for (let i = 1; i < 4; i++) {
            columns.push({value: i, label: i});
        }

        return columns;
    };

    return (
        <div className="cr-form">
            <h3>Customize</h3>

            <form ref={ref} onSubmit={props.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="crTitle">Title:</label>
                    <input type="text" className="form-control" id="crTitle" name="crTitle"/>
                </div>

                <div className="form-group">
                    <label htmlFor="crRows">Rows:</label>
                    <select name="cr-rows" id="crRows" className="form-control">
                        <option value={null}>Rows</option>
                        {
                            getRows().map(row => (
                                <option key={row.value} value={row.value}>{row.label}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="crColumns">Columns:</label>
                    <select name="cr-columns" id="crColumns" className="form-control">
                        <option value={null}>Columns</option>
                        {
                            getColumns().map(column => (
                                <option key={column.value} value={column.value}>{column.label}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="form-group">
                    <label>Display additional data:</label>

                    <label className="ci-control ci-control-checkbox" htmlFor="crAuthor">
                        <input type="checkbox"
                               id="crAuthor"
                               name="crAuthor"
                               value={"author"}
                               onChange={e => props.toggleCheckboxes(e, "author")}
                               checked={props.checkboxes.author === "author"}
                        />
                        <div className="ci-control-indicator" />
                       Author
                    </label>

                    <label className="ci-control ci-control-checkbox" htmlFor="crSection">
                        <input type="checkbox"
                               id="crSection"
                               name="crSection"
                               value={"section"}
                               onChange={e => props.toggleCheckboxes(e, "section")}
                               checked={props.checkboxes.section === "section"}
                        />
                        <div className="ci-control-indicator" />
                        Section
                    </label>

                    <label className="ci-control ci-control-checkbox" htmlFor="crTopic">
                        <input type="checkbox"
                               id="crTopic"
                               name="crTopic"
                               value={"topic"}
                               onChange={e => props.toggleCheckboxes(e, "topic")}
                               checked={props.checkboxes.topic === "topic"}
                        />
                        <div className="ci-control-indicator" />
                        Topic
                    </label>

                    <label className="ci-control ci-control-checkbox" htmlFor="crDate">
                        <input type="checkbox"
                               id="crDate"
                               name="crDate"
                               value={"date"}
                               onChange={e => props.toggleCheckboxes(e, "date")}
                               checked={props.checkboxes.date === "date"}
                        />
                        <div className="ci-control-indicator" />
                       Date published
                    </label>

                </div>

                <div className="form-group">
                    <button className="btn btn-primary">Apply</button>
                </div>
            </form>
        </div>
    );
});

export default CrForm;