import React, {Component} from "react";

import ErrorBoundary from "./ErrorBoundary";
import PageTitle from "./PageTitle";
import CRForm from "./CRForm";
import CRPreview from "./CRPreview";
import CRCode from "./CRCode";

class CRWizard extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleCheckboxes = this.toggleCheckboxes.bind(this);

        this.state = {
            formValues: {
                title: null,
                rows: 2,
                columns: 3,
                author: null,
                section: null,
                topic: null,
                date: null,
            },
            checkboxes: {
                author: null,
                section: null,
                topic: null,
                date: null,
            }
        };
    }

    toggleCheckboxes(e, name) {
        const checkboxes = {...this.state.checkboxes};
        if (e.target.checked) {
            checkboxes[name] = e.target.value;

            this.setState({
                checkboxes
            });
        } else {
            checkboxes[name] = null;
            this.setState({
                checkboxes
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const form = this.form.current;
        const formValues = {
            title: form.crTitle.value,
            rows: parseInt(form.crRows.value),
            columns: parseInt(form.crColumns.value),
            author: this.state.checkboxes.author,
            section: this.state.checkboxes.section,
            topic: this.state.checkboxes.topic,
            date: this.state.checkboxes.date,
        };

        this.setState({formValues});
    }

    render() {
        return (
            <div className="cr-page container">
                <div className="row">
                    <ErrorBoundary>
                        <PageTitle/>
                    </ErrorBoundary>
                </div>

                <div className="row">
                    <ErrorBoundary>
                        <CRForm
                            ref={this.form}
                            handleSubmit={this.handleSubmit}
                            checkboxes={this.state.checkboxes}
                            toggleCheckboxes={this.toggleCheckboxes}
                        />
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <CRPreview formValues={this.state.formValues}/>
                    </ErrorBoundary>
                </div>
                <div className="row">
                    <h3 className="example-code-title">Example embed code:</h3>
                    <ErrorBoundary>
                        <CRCode formValues={this.state.formValues}/>
                    </ErrorBoundary>
                </div>
            </div>
        );
    }

}

export default CRWizard;