import React from 'react';
import './App.scss';
import Navigation from "./components/Navigation";
import CRWizard from "./components/CRWizard";

function App() {
  return (
    <div className="cr-demo">
        <Navigation/>
        <CRWizard/>
    </div>
  );
}

export default App;
