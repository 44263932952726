import React from "react";

const PageTitle = () => {
    return (
        <div className="profile-details cr-wizard-profile-details">
            <div className="page-title">
                <h1>Demo</h1>
            </div>
            <p className="page-subtitle">— This page demonstrates basic widget behaviour, layout and structure.</p>
        </div>
    );
};

export default PageTitle;