import React, {Component} from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null
        };

    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // console.log(error, errorInfo);
    }

    render() {
        if (this.state.error) {
            return (
                <div className="alert alert-warning error-handler-alert">
                    <strong>Something went wrong.</strong>{" "}
                    Please refresh the page and try again.
                </div>
            );
        }

        return this.props.children;
    }
}

export default (ErrorBoundary);