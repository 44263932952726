import React, {useState} from "react";
import ReactDOMServer from "react-dom/server";

const posts = [
    {
        title: "Use Case: how to grow your company blog with the help of Content Insights",
        image: "https://do21oebltgtwd.cloudfront.net/2358/215.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "Introducing new Account & Organization management in Content Insights",
        image: "https://do21oebltgtwd.cloudfront.net/2358/161.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "How to win in the fight against ad-blockers",
        image: "https://do21oebltgtwd.cloudfront.net/2358/165.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "5 biggest native advertising challenges, and how to overcome them",
        image: "https://do21oebltgtwd.cloudfront.net/2358/183.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "[PART 3] How to grow your ad revenue with the help of right metrics",
        image: "https://do21oebltgtwd.cloudfront.net/2358/215.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "Use Case: how to grow your company blog with the help of Content Insights",
        image: "https://do21oebltgtwd.cloudfront.net/2358/215.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "Introducing new Account & Organization management in Content Insights",
        image: "https://do21oebltgtwd.cloudfront.net/2358/161.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "How to win in the fight against ad-blockers",
        image: "https://do21oebltgtwd.cloudfront.net/2358/165.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "5 biggest native advertising challenges, and how to overcome them",
        image: "https://do21oebltgtwd.cloudfront.net/2358/183.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "[PART 3] How to grow your ad revenue with the help of right metrics",
        image: "https://do21oebltgtwd.cloudfront.net/2358/215.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },

    {
        title: "Use Case: how to grow your company blog with the help of Content Insights",
        image: "https://do21oebltgtwd.cloudfront.net/2358/215.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "Introducing new Account & Organization management in Content Insights",
        image: "https://do21oebltgtwd.cloudfront.net/2358/161.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "How to win in the fight against ad-blockers",
        image: "https://do21oebltgtwd.cloudfront.net/2358/165.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "5 biggest native advertising challenges, and how to overcome them",
        image: "https://do21oebltgtwd.cloudfront.net/2358/183.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
    {
        title: "[PART 3] How to grow your ad revenue with the help of right metrics",
        image: "https://do21oebltgtwd.cloudfront.net/2358/215.jpg",
        author: "Johnny Jones",
        topic: "Topic",
        section: "section",
        date: "23.9.2019"
    },
];

const CrPreview = (props) => {
    const [active, setActive] = useState("desktop");


    const getClasses = () => {
        const columns = props.formValues.columns ? props.formValues.columns : 3;

        switch (columns) {
            case 1:
                return "cinsights-widget__col-lg-12";
            case 2:
                return "cinsights-widget__col-lg-6 cinsights-widget__col-md-6 cinsights-widget__col-sm-12";
            case 3:
                return "cinsights-widget__col-lg-4 cinsights-widget__col-md-4 cinsights-widget__col-sm-12";
            case 4:
                return "cinsights-widget__col-lg-3 cinsights-widget__col-md-4 cinsights-widget__col-sm-12";
            default:
                return "";
        }
    };

    const getPosts = () => {
        const rows = props.formValues.rows ? props.formValues.rows : 2;
        const columns = props.formValues.columns ? props.formValues.columns : 3;
        const postNumber = rows * columns;

        return posts.slice(0, postNumber);
    };

    const getMarkup = () => {
        const template = ReactDOMServer.renderToStaticMarkup(
            <div className="cinsights-widget__container-fluid">
                {
                    props.formValues.title && (
                        <h2 className="cinsights-widget__headline">{props.formValues.title}</h2>
                    )
                }
                <div className="cinsights-widget__row">
                    {
                        getPosts().map((post, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`cinsights-widget__card ${getClasses()}`}
                                >
                                    <div className="cinsights-widget__card-image">
                                        {/*  eslint-disable-next-line */}
                                        <a href="#">
                                            {/*  eslint-disable-next-line */}
                                            <img src={post.image} alt="Article image"/>
                                        </a>
                                    </div>
                                    <div className="cinsights-widget__card-meta">
                                        {
                                            props.formValues.section && (
                                                <span className="cinsights-widget__card-meta-item left">
                                                    {post.section}
                                                </span>
                                            )
                                        }

                                        {
                                            props.formValues.topic && (
                                                <span className="cinsights-widget__card-meta-item right">{post.topic}</span>

                                            )
                                        }
                                    </div>
                                    <div className="cinsights-widget__card-headline">
                                        {/*  eslint-disable-next-line */}
                                        <a href="#">
                                            <h2>{post.title}</h2>
                                        </a>
                                    </div>
                                    <div className="cinsights-widget__card-meta">
                                        {
                                            props.formValues.date && (
                                                <time aria-label={post.date} className="cinsights-widget__card-meta-item left">
                                                    {post.date}
                                                </time>
                                            )
                                        }

                                        {
                                            props.formValues.author && (
                                                <span className="cinsights-widget__card-meta-item right">{post.author}</span>

                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>

            </div>);


        return  `
            <html lang="en">
                <head>
                    <title>Preview</title>
                    <script src="//use.typekit.net/lpx1hnl.js"></script>
                    <script>
                        window.loadingFontsStatus = "";
                        try {
                            Typekit.load({
                                loading: function() {
                                    window.loadingFontsStatus = "loading";
                                },
                                active: function() {
                                    window.loadingFontsStatus = "active";
                                },
                                inactive: function() {
                                    window.loadingFontsStatus = "inactive";
                                }
                            });
                        } catch(e){}
                    </script>
                    <link rel="stylesheet" href="/widget_styles.css">
                </head>
                <body>${template}</body>
                
            </html>
        `;
    };

    return (
        <div className="cr-preview">
            <h3>Preview</h3>

            <div className="form-group">
                <button
                    className={`btn btn-filter ${active === "desktop" ? "active" : ""}`}
                    onClick={() => setActive("desktop")}
                >
                   Desktop
                </button>
                <button
                    className={`btn btn-filter ${active === "mobile" ? "active" : ""}`}
                    onClick={() => setActive("mobile")}
                >
                   Mobile
                </button>
            </div>

            <div className={`cr-preview-box ${active === "mobile" ? "cr-preview-box-mobile" : ""}`}>
                <iframe srcDoc={getMarkup()}></iframe>
            </div>
        </div>
    );

};

export default CrPreview;