import React, {Fragment} from "react";

const CrCode = (props) => {
    const {title, rows, columns, author, section, topic, date} = props.formValues;


    return (
        <div className="cr-code-preview">
            <div className="cr-code">
                <span>{`_ain.widget({`}</span>
                <span>element:"#ain-widget-1",</span>
                {title && <span>{`title: "${title}",`}</span>}
                {(rows && rows !== 2) ? <span>{`rows: ${rows},`}</span> : ""}
                {(columns && columns !== 3) ? <span>{`columns: ${columns},`}</span> : ""}
                {
                    (author || section || topic || date) && (
                       <Fragment>
                           <span>{`metadata: {`}</span>
                           {date && <span className="meta">{`"date" : true,`}</span>}
                           {topic && <span className="meta">{`"topics": true,`}</span>}
                           {section && <span className="meta">{`"sections" : true,`}</span>}
                           {author &&  <span className="meta">{`"authors" : true`}</span>}

                           <span>{`}`}</span>
                       </Fragment>
                    )
                }
                <span>{`});`}</span>
            </div>
        </div>
    );
};

export default CrCode;