import React from "react";

const Navigation = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light main-nav">
            <a className="navbar-brand" href="https://contentinsights.com">
                <img src="/images/ci-logo.svg" alt="Content Insights"/>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="https://contentinsights.com/how-it-works/">
                            How it works
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://contentinsights.com/features/">
                            Features
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://contentinsights.com/about-us/">
                            About us
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://contentinsights.com/blog/">
                            Blog
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://contentinsights.com/career/">
                            Career
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://contentinsights.com/faq/">
                            FAQ
                        </a>
                    </li>
                </ul>

                <ul className="navbar-nav my-2 my-lg-0">
                    <li className="nav-item">
                        <a href="https://app.contentinsights.com/user/login" className="nav-link">Login</a>
                    </li>

                    <li className="nav-item">
                        <a href="https://contentinsights.com/requestdemo" className="nav-link">
                            Request live demo
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;